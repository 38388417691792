import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
const Hero = () => {
    return(                	
        <section id="header" className="hero-slideshow-wrapper hero-slideshow-normal loaded">
            <div className="container" style={{paddingTtop: "10%", paddingBbottom: "10%"}}>
                <div className="row hero__content hero-content-style2">
                    <div className="col-md-12 col-lg-6">
                        <div className="hcl2-content">
                            <h1>AK MANAGEMENT CORPORATION LTD</h1>
                            <p>Private company limited by shares<br/>Сompany N: 13091700</p>
                            <p>
                                <Button variant="outline-secondary" size="lg" href="#contact">Contact Us</Button>
                            </p>
                        </div>						
                    </div>
                    <div className="col-md-12 col-lg-6"></div>
                </div>
            </div>
        </section>
    );    
};

export default Hero;