const Header = () => {
    return(
        <header id="masthead" class="site-header header-full-width is-sticky no-scroll is-t h-on-top" role="banner" style={{top: "0px"}}>
            <div className="container">
                <div className="site-branding">
                    <div className="site-brand-inner no-logo-img has-title">
                        <h1 className="site-title">
                            <a class="site-text-logo" href="http://www.akmanagement.uk/" rel="home">AK MANAGEMENT CORPORATION  LTD</a>
                        </h1>
                    </div>				
                </div>
            </div>
        </header>
    );
};

export default Header;